import type { UseFetchOptions } from 'nuxt/app'

/**
 * A composable function for making API requests using Nuxt's useFetch.
 *
 * @template T - The expected type of the API response data.
 * @param {string | (() => string)} url - The URL for the API request. Can be a string or a function that returns a string.
 * @param {UseFetchOptions<T>} [options] - Optional configuration options for the fetch request.
 * @returns {ReturnType<typeof useFetch>} - The result of the useFetch call, including data, error, and other properties.
 *
 * @example
 * const { data, error } = await useAPI<UserData>('/api/user')
 */
export function useAPI<T>(
	url: string | (() => string),
	options?: UseFetchOptions<T>
) {
	return useFetch(url, {
		key: options?.key ? options.key : `${url}`,
		...options,
		cache: 'no-cache',
		headers: {
			'Cache-Control': 'max-age=3600'
		},
		// server: false,
		dedupe: 'cancel',
		$fetch: useNuxtApp().$api as typeof $fetch
	})
}
